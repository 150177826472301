<!--
Config for questioncards
mode: display, create, vote, results
data: Based on type & mode
-->
<template>
  <div>
    <h3>{{ question.title }}</h3>
    <!-- Answers -->
    <div v-if="mode == 'reporting'">
      <OpenText
        v-if="question.type == 'openText'"
        :mode="mode"
        @inputChange="$emit('inputChange', $event)"
        :data="question.answers"
        :reportingResult="reporting.result"
      />
      <MultipleChoice
        v-if="question.type == 'multipleChoice'"
        :mode="mode"
        @inputChange="$emit('inputChange', $event)"
        :data="question.answers"
        :reportingResult="reporting.answers"
        :totalVotes="totalVotes"
      />
    </div>
    <div v-else>
      <OpenText
        v-if="question.type == 'openText'"
        :mode="mode"
        @inputChange="$emit('inputChange', $event)"
      />
      <MultipleChoice
        v-if="question.type == 'multipleChoice'"
        :mode="mode"
        @inputChange="$emit('inputChange', $event)"
        :data="question.answers"
      />
    </div>
  </div>
</template>

<script>
import MultipleChoice from "./MultipleChoice";
import OpenText from "./OpenText";

export default {
  name: "Question",
  props: {
    mode: String,
    question: Object,
    totalVotes: {
      type: Number,
      default: function() {
        return 0;
      }
    },
    reporting: Object
  },
  components: { MultipleChoice, OpenText }
};
</script>

<style lang="scss" scoped>
h4 {
  margin: 0;
}
.el-card {
  padding: 10px;
}
.el-radio {
  display: block;
  margin-top: 5px;
}
</style>
