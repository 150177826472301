<template>
  <div class="surveyContainer">
    <div class="surveyHeader">
      <el-row type="flex" align="top">
        <el-col :span="18">
          <h2 class="m-b-0">{{data.title}}</h2>
          <p class="description">{{ data.description }}</p>
          <el-tag class="m-t-1" size="small">{{data.teamName}}</el-tag>
        </el-col>
        <el-col :span="6" class="ta-r">
          <div v-if="data.status == 'draft'" class="statusWrapper">
            <span>Vorlage</span>
          </div>
        </el-col>
      </el-row>
      <el-alert
        class="m-t-3"
        v-if="data.isAnonymous"
        title="Diese Umfrage ist anonym"
        type="info"
        show-icon
      ></el-alert>
    </div>
    <div class="surveyContent">
      <div v-if="!creationMode">
        <div v-if="votingMode">
          <Question
            v-for="(question, index) in data.questions"
            :key="index"
            :question="question"
            @inputChange="updateQuestionAnswer($event, index)"
            mode="voting"
          />
        </div>
        <div v-else-if="data.status == 'draft'">
          <Question
            v-for="(question, index) in data.questions"
            :key="index"
            :question="question"
            mode="display"
          />
        </div>
        <div v-else-if="reportingMode">
          <Question
            v-for="(question, index) in data.questions"
            :key="index"
            :question="question"
            :reporting="reporting.questions[index]"
            :totalVotes="reporting.totalVotes"
            mode="reporting"
          />
        </div>
        <div v-else>
          <Question
            v-for="(question, index) in data.questions"
            :key="index"
            :question="question"
            mode="display"
          />
        </div>
      </div>

      <!-- For Creation Mode to drag and drop questions -->
      <slot v-if="creationMode" />

      <div class="t-a-r m-t-3">
        <el-button
          v-if="votingMode"
          type="primary"
          :loading="isLoadingSubmission"
          @click="submitAnswers()"
        >Feedback senden</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Question from "./Question";
export default {
  components: { Question },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return {
          title: "",
          status: "",
          teamName: "",
          questions: []
        };
      }
    },
    reporting: Object,
    creationMode: Boolean,
    votingMode: Boolean,
    reportingMode: Boolean
  },
  data() {
    return {
      answers: [],
      isLoadingSubmission: false
    };
  },
  methods: {
    updateQuestionAnswer(event, index) {
      this.answers[index] = event;
    },
    submitAnswers() {
      // Validate answers
      this.isLoadingSubmission = true;
      let answerObject = [];
      this.data.questions.forEach((question, index) => {
        answerObject[index] = {
          type: question.type,
          answer: this.answers[index]
        };
      });
      this.$emit("submitAnswers", answerObject);
    }
  }
};
</script>

<style lang="scss" scoped>
.surveyContainer {
  background: white;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.surveyHeader {
  border-bottom: 1px solid #e0e0e0;
  padding: 30px;

  .statusWrapper {
    text-align: center;
    color: rgb(124, 124, 124);
    display: inline-block;

    .statusIcon {
      display: block;
      margin: auto;
    }
  }
  h2 {
    margin-bottom: 5px;
    margin-top: 0px;
  }

  .description {
    margin-top: 10px;
    margin-bottom: 15px;
    color: rgb(124, 124, 124);
  }
}

.surveyContent {
  padding: 30px;
}
</style>