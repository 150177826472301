<template>
  <div>
    <el-radio-group>
      <el-radio v-for="(answer, index) in value" :key="index" label="x">
        <input type="text" :value="answer.title" :ref="index" @input="updateValue($event, index)" />
      </el-radio>
    </el-radio-group>
    <el-button type="text" @click="addAnswer">Antwortmöglichkeit hinzufügen</el-button>
  </div>
</template>

<script>
export default {
  props: ["value"],
  name: "MultipleChoice",
  methods: {
    updateValue(e, index) {
      let updatedArray = [...this.value];
      updatedArray[index] = { title: e.target.value };
      this.$emit("input", updatedArray);
    },
    addAnswer() {
      let updatedArray = [...this.value];
      updatedArray.push({ title: "Antwort" });
      this.$emit("input", updatedArray);
    }
  }
};
</script>

<style scoped lang="scss">
.el-radio {
  display: block;
  margin-bottom: 5px;
}
.el-button {
  display: block;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
input {
  border: none;
  font-size: 14px;
  color: rgb(88, 88, 88);
}
textarea,
select,
input,
button {
  outline: none;
}
</style>
