var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-steps",
        {
          staticClass: "m-t-5 m-b-5",
          attrs: {
            active: _vm.currentStep,
            "finish-status": "success",
            "align-center": ""
          }
        },
        [
          _c("el-step", { attrs: { title: "Umfrage erstellen" } }),
          _c("el-step", { attrs: { title: "Fragen erstellen" } }),
          _c("el-step", { attrs: { title: "Umfrage überprüfen" } })
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { type: "flex", align: "top", gutter: 30 } },
        [
          _c(
            "el-col",
            { attrs: { span: 16 } },
            [
              _vm.currentStep == 1
                ? _c(
                    "Survey",
                    { attrs: { data: _vm.survey, creationMode: "" } },
                    [
                      _vm.survey.questions.length == 0
                        ? _c("div", { staticClass: "noQuestionsInfo" }, [
                            _c("h5", [
                              _vm._v("Neue Fragen unterhalb reinziehen")
                            ])
                          ])
                        : _vm._e(),
                      _c(
                        "draggable",
                        {
                          staticClass: "draggableContainer",
                          attrs: {
                            list: _vm.survey.questions,
                            group: "questions"
                          }
                        },
                        _vm._l(_vm.survey.questions, function(question, index) {
                          return _c("QuestionCreate", {
                            key: question.id,
                            on: {
                              delete: function($event) {
                                return _vm.deleteQuestion(index)
                              }
                            },
                            model: {
                              value: _vm.survey.questions[index],
                              callback: function($$v) {
                                _vm.$set(_vm.survey.questions, index, $$v)
                              },
                              expression: "survey.questions[index]"
                            }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _c("Survey", { attrs: { data: _vm.survey } })
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _vm.currentStep == 0
                ? _c("BasicSettings", {
                    on: {
                      update: function($event) {
                        return _vm.updateSurveyData($event)
                      },
                      next: function($event) {
                        return _vm.nextStep()
                      }
                    }
                  })
                : _vm._e(),
              _vm.currentStep == 1
                ? _c("QuestionSelection", {
                    on: {
                      update: function($event) {
                        return _vm.updateSurveyData($event)
                      },
                      next: function($event) {
                        return _vm.nextStep()
                      }
                    }
                  })
                : _vm._e(),
              _vm.currentStep == 2
                ? _c("SaveDraft", {
                    attrs: { isLoading: _vm.isLoading },
                    on: {
                      next: function($event) {
                        return _vm.nextStep()
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }