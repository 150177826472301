<template>
  <div>
    <!-- Display Mode -->
    <div v-if="mode == 'display'">
      <el-input type="textarea" :rows="2" placeholder="Antwort hier eingeben" disabled></el-input>
    </div>

    <!-- Vote Mode -->
    <div v-if="mode == 'voting'">
      <el-input
        type="textarea"
        :rows="2"
        placeholder="Bitte geben Sie Ihre Antwort ein"
        v-model="input"
        @change="$emit('inputChange', $event)"
      ></el-input>
    </div>

    <!-- Reporting Mode -->
    <div v-if="mode == 'reporting'">
      <div v-if="numberResults > 3">
        <li v-for="(answer, index) in resultList" v-bind:key="'s' + index">{{answer}}</li>
        <el-button
          v-if="showResultList == false"
          @click="toggleResultList()"
          type="text"
        >Weitere Antworten anzeigen ({{this.totalVotes - 3 }})</el-button>
        <el-button
          v-if="showResultList == true"
          @click="toggleResultList()"
          type="text"
        >Weniger Antworten anzeigen</el-button>
      </div>
      <div v-else-if="numberResults == 0">
        <p>Es hat noch niemand abgestimmt.</p>
      </div>
      <div v-else>
        <li v-for="(answer, index) in resultList" v-bind:key="index">{{answer}}</li>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OpenText",
  props: {
    mode: String,
    data: Object,
    totalVotes: Number,
    reportingResult: Array
  },
  data() {
    return {
      input: "",
      showResultList: false
    };
  },
  computed: {
    numberResults() {
      return this.reportingResult.length;
    },
    resultList: function() {
      if (this.showResultList) {
        return this.reportingResult;
      } else {
        return this.reportingResult.filter((element, index) => index < 3);
      }
    }
  },
  methods: {
    toggleResultList() {
      this.showResultList = !this.showResultList;
    }
  }
};
</script>

<style lang="scss" scoped>
.el-button {
  padding-bottom: 0;
}
</style>
