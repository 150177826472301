var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.mode == "display"
      ? _c(
          "div",
          [
            _c("el-input", {
              attrs: {
                type: "textarea",
                rows: 2,
                placeholder: "Antwort hier eingeben",
                disabled: ""
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.mode == "voting"
      ? _c(
          "div",
          [
            _c("el-input", {
              attrs: {
                type: "textarea",
                rows: 2,
                placeholder: "Bitte geben Sie Ihre Antwort ein"
              },
              on: {
                change: function($event) {
                  return _vm.$emit("inputChange", $event)
                }
              },
              model: {
                value: _vm.input,
                callback: function($$v) {
                  _vm.input = $$v
                },
                expression: "input"
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.mode == "reporting"
      ? _c("div", [
          _vm.numberResults > 3
            ? _c(
                "div",
                [
                  _vm._l(_vm.resultList, function(answer, index) {
                    return _c("li", { key: "s" + index }, [
                      _vm._v(_vm._s(answer))
                    ])
                  }),
                  _vm.showResultList == false
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.toggleResultList()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "Weitere Antworten anzeigen (" +
                              _vm._s(this.totalVotes - 3) +
                              ")"
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.showResultList == true
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.toggleResultList()
                            }
                          }
                        },
                        [_vm._v("Weniger Antworten anzeigen")]
                      )
                    : _vm._e()
                ],
                2
              )
            : _vm.numberResults == 0
            ? _c("div", [_c("p", [_vm._v("Es hat noch niemand abgestimmt.")])])
            : _c(
                "div",
                _vm._l(_vm.resultList, function(answer, index) {
                  return _c("li", { key: index }, [_vm._v(_vm._s(answer))])
                }),
                0
              )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }