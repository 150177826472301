var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "questionWrapper" },
    [
      _c("el-button", {
        staticClass: "questionDelete",
        attrs: {
          type: "danger",
          size: "mini",
          icon: "el-icon-delete",
          circle: ""
        },
        on: {
          click: function($event) {
            return _vm.$emit("delete")
          }
        }
      }),
      _c("input", {
        ref: "title",
        staticClass: "headlineForm",
        attrs: { type: "text", width: "100%" },
        domProps: { value: _vm.value.title },
        on: {
          input: function($event) {
            return _vm.updateQuestion()
          }
        }
      }),
      _vm.value.type == "openText" ? _c("OpenText") : _vm._e(),
      _vm.value.type == "multipleChoice"
        ? _c("MultipleChoice", {
            model: {
              value: _vm.value.answers,
              callback: function($$v) {
                _vm.$set(_vm.value, "answers", $$v)
              },
              expression: "value.answers"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }