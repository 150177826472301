
<template>
  <div class="questionWrapper">
    <el-button
      class="questionDelete"
      type="danger"
      size="mini"
      icon="el-icon-delete"
      circle
      @click="$emit('delete')"
    ></el-button>
    <input
      class="headlineForm"
      type="text"
      :value="value.title"
      ref="title"
      width="100%"
      @input="updateQuestion()"
    />
    <!-- Answers  -->
    <OpenText v-if="value.type == 'openText'" />
    <MultipleChoice v-if="value.type == 'multipleChoice'" v-model="value.answers" />
  </div>
</template>

<script>
import MultipleChoice from "./MultipleChoice";
import OpenText from "./OpenText";

export default {
  name: "Question",
  props: ["value"],
  components: { MultipleChoice, OpenText },
  data() {
    return {};
  },
  methods: {
    updateQuestion() {
      this.$emit("input", {
        ...this.value,
        title: this.$refs.title.value
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.questionWrapper {
  margin-bottom: 25px;
  border: 1px solid #eee;
  padding: 20px;
  position: relative;

  .questionDelete {
    position: absolute;
    right: -10px;
    top: -7px;
  }
}
h4 {
  margin: 0;
}
.headlineForm {
  border: none;
  color: #122c51;
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 15px;
  width: 100%;
}
.el-radio {
  display: block;
  margin-top: 5px;
}
textarea,
select,
input,
button {
  outline: none;
}
</style>
