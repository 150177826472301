<template>
  <SideBarCard title="Fragen hinzufügen">
    <draggable
      :list="questions"
      :group="{ name:'questions', pull: 'clone', put: false }"
      :clone="clone"
    >
      <div class="questionCard" v-for="question in questions" :key="question.id">{{question.title}}</div>
    </draggable>

    <div class="ta-r">
      <el-button @click="onSubmit()" type="primary">Weiter</el-button>
    </div>
  </SideBarCard>
</template>

<script>
import draggable from "vuedraggable";
import SideBarCard from "@/components/Layout/SideBarCard";
import uniqid from "uniqid";

export default {
  components: { SideBarCard, draggable },
  data() {
    return {
      questions: [
        {
          title: "Offener Text",
          type: "openText",
          id: "aoiu98z"
        },
        {
          title: "Multiple Choice",
          type: "multipleChoice",
          answers: [{ title: "A" }, { title: "B" }],
          id: "dia9873"
        }
      ]
    };
  },
  methods: {
    onSubmit() {
      // Valid form
      this.$emit("next");
    },
    clone({ title, type, answers }) {
      let question = { title, type, id: uniqid() };
      if (answers) {
        question.answers = answers;
      }

      return question;
    }
  }
};
</script>

<style lang="scss" scoped>
.questionCard {
  background: #eee;
  color: rgb(100, 100, 100);
  font-weight: 700;
  margin-bottom: 25px;
  padding: 15px;
}
</style>