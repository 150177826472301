var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SideBarCard",
    { attrs: { title: "Grundeinstellungen" } },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit()
            }
          }
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "title",
                rules: {
                  required: true,
                  message: "Dieses Feld darf nicht leer sein",
                  trigger: "blur"
                }
              }
            },
            [
              _c("el-input", {
                attrs: { placeholder: "Title" },
                on: { blur: _vm.updateSurveyData },
                model: {
                  value: _vm.form.title,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "description",
                rules: {
                  required: true,
                  message: "Dieses Feld darf nicht leer sein",
                  trigger: "blur"
                }
              }
            },
            [
              _c("el-input", {
                attrs: { type: "textarea", placeholder: "Description" },
                model: {
                  value: _vm.form.description,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "Select" },
                  model: {
                    value: _vm.form.category,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "category", $$v)
                    },
                    expression: "form.category"
                  }
                },
                _vm._l(_vm.categories, function(category) {
                  return _c("el-option", {
                    key: category,
                    attrs: { label: category, value: category }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ta-r" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onSubmit()
                    }
                  }
                },
                [_vm._v("Weiter")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }