var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SideBarCard",
    { attrs: { title: "Fragen hinzufügen" } },
    [
      _c(
        "draggable",
        {
          attrs: {
            list: _vm.questions,
            group: { name: "questions", pull: "clone", put: false },
            clone: _vm.clone
          }
        },
        _vm._l(_vm.questions, function(question) {
          return _c("div", { key: question.id, staticClass: "questionCard" }, [
            _vm._v(_vm._s(question.title))
          ])
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "ta-r" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  return _vm.onSubmit()
                }
              }
            },
            [_vm._v("Weiter")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }