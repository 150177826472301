var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "surveyContainer" }, [
    _c(
      "div",
      { staticClass: "surveyHeader" },
      [
        _c(
          "el-row",
          { attrs: { type: "flex", align: "top" } },
          [
            _c(
              "el-col",
              { attrs: { span: 18 } },
              [
                _c("h2", { staticClass: "m-b-0" }, [
                  _vm._v(_vm._s(_vm.data.title))
                ]),
                _c("p", { staticClass: "description" }, [
                  _vm._v(_vm._s(_vm.data.description))
                ]),
                _c(
                  "el-tag",
                  { staticClass: "m-t-1", attrs: { size: "small" } },
                  [_vm._v(_vm._s(_vm.data.teamName))]
                )
              ],
              1
            ),
            _c("el-col", { staticClass: "ta-r", attrs: { span: 6 } }, [
              _vm.data.status == "draft"
                ? _c("div", { staticClass: "statusWrapper" }, [
                    _c("span", [_vm._v("Vorlage")])
                  ])
                : _vm._e()
            ])
          ],
          1
        ),
        _vm.data.isAnonymous
          ? _c("el-alert", {
              staticClass: "m-t-3",
              attrs: {
                title: "Diese Umfrage ist anonym",
                type: "info",
                "show-icon": ""
              }
            })
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "surveyContent" },
      [
        !_vm.creationMode
          ? _c("div", [
              _vm.votingMode
                ? _c(
                    "div",
                    _vm._l(_vm.data.questions, function(question, index) {
                      return _c("Question", {
                        key: index,
                        attrs: { question: question, mode: "voting" },
                        on: {
                          inputChange: function($event) {
                            return _vm.updateQuestionAnswer($event, index)
                          }
                        }
                      })
                    }),
                    1
                  )
                : _vm.data.status == "draft"
                ? _c(
                    "div",
                    _vm._l(_vm.data.questions, function(question, index) {
                      return _c("Question", {
                        key: index,
                        attrs: { question: question, mode: "display" }
                      })
                    }),
                    1
                  )
                : _vm.reportingMode
                ? _c(
                    "div",
                    _vm._l(_vm.data.questions, function(question, index) {
                      return _c("Question", {
                        key: index,
                        attrs: {
                          question: question,
                          reporting: _vm.reporting.questions[index],
                          totalVotes: _vm.reporting.totalVotes,
                          mode: "reporting"
                        }
                      })
                    }),
                    1
                  )
                : _c(
                    "div",
                    _vm._l(_vm.data.questions, function(question, index) {
                      return _c("Question", {
                        key: index,
                        attrs: { question: question, mode: "display" }
                      })
                    }),
                    1
                  )
            ])
          : _vm._e(),
        _vm.creationMode ? _vm._t("default") : _vm._e(),
        _c(
          "div",
          { staticClass: "t-a-r m-t-3" },
          [
            _vm.votingMode
              ? _c(
                  "el-button",
                  {
                    attrs: {
                      type: "primary",
                      loading: _vm.isLoadingSubmission
                    },
                    on: {
                      click: function($event) {
                        return _vm.submitAnswers()
                      }
                    }
                  },
                  [_vm._v("Feedback senden")]
                )
              : _vm._e()
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }