var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", [_vm._v(_vm._s(_vm.question.title))]),
    _vm.mode == "reporting"
      ? _c(
          "div",
          [
            _vm.question.type == "openText"
              ? _c("OpenText", {
                  attrs: {
                    mode: _vm.mode,
                    data: _vm.question.answers,
                    reportingResult: _vm.reporting.result
                  },
                  on: {
                    inputChange: function($event) {
                      return _vm.$emit("inputChange", $event)
                    }
                  }
                })
              : _vm._e(),
            _vm.question.type == "multipleChoice"
              ? _c("MultipleChoice", {
                  attrs: {
                    mode: _vm.mode,
                    data: _vm.question.answers,
                    reportingResult: _vm.reporting.answers,
                    totalVotes: _vm.totalVotes
                  },
                  on: {
                    inputChange: function($event) {
                      return _vm.$emit("inputChange", $event)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
      : _c(
          "div",
          [
            _vm.question.type == "openText"
              ? _c("OpenText", {
                  attrs: { mode: _vm.mode },
                  on: {
                    inputChange: function($event) {
                      return _vm.$emit("inputChange", $event)
                    }
                  }
                })
              : _vm._e(),
            _vm.question.type == "multipleChoice"
              ? _c("MultipleChoice", {
                  attrs: { mode: _vm.mode, data: _vm.question.answers },
                  on: {
                    inputChange: function($event) {
                      return _vm.$emit("inputChange", $event)
                    }
                  }
                })
              : _vm._e()
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }