var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-radio-group",
        _vm._l(_vm.value, function(answer, index) {
          return _c("el-radio", { key: index, attrs: { label: "x" } }, [
            _c("input", {
              ref: index,
              refInFor: true,
              attrs: { type: "text" },
              domProps: { value: answer.title },
              on: {
                input: function($event) {
                  return _vm.updateValue($event, index)
                }
              }
            })
          ])
        }),
        1
      ),
      _c(
        "el-button",
        { attrs: { type: "text" }, on: { click: _vm.addAnswer } },
        [_vm._v("Antwortmöglichkeit hinzufügen")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }