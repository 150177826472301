<template>
  <SideBarCard title="Grundeinstellungen">
    <el-form :model="form" @submit.native.prevent="onSubmit()" ref="form">
      <el-form-item
        prop="title"
        :rules="{
          required: true,
          message: 'Dieses Feld darf nicht leer sein',
          trigger: 'blur'
        }"
      >
        <el-input v-model="form.title" @blur="updateSurveyData" placeholder="Title"></el-input>
      </el-form-item>
      <el-form-item
        prop="description"
        :rules="{
          required: true,
          message: 'Dieses Feld darf nicht leer sein',
          trigger: 'blur'
        }"
      >
        <el-input type="textarea" v-model="form.description" placeholder="Description"></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.category" placeholder="Select">
          <el-option
            v-for="category in categories"
            :key="category"
            :label="category"
            :value="category"
          ></el-option>
        </el-select>
      </el-form-item>
      <div class="ta-r">
        <el-button @click="onSubmit()" type="primary">Weiter</el-button>
      </div>
    </el-form>
  </SideBarCard>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import SideBarCard from "@/components/Layout/SideBarCard";

export default {
  components: { SideBarCard },
  data() {
    return {
      form: {
        title: "",
        description: "",
        category: ""
      },
      categories: ["basics", "project_management", "motivation"]
    };
  },
  computed: {},
  methods: {
    updateSurveyData() {
      this.$refs["form"].validate(async valid => {
        if (valid) {
          // Valid form
          // Set category name

          this.$emit("update", this.form);
        }
      });
    },
    onSubmit() {
      this.$refs["form"].validate(async valid => {
        if (valid) {
          // Valid form
          this.updateSurveyData();
          this.$emit("next");
        }
      });
    }
  }
};
</script>