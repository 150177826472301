<template>
  <div>
    <!-- Display Mode -->
    <div v-if="mode == 'display'">
      <div>
        <el-radio-group>
          <el-radio v-for="(answer, index) in data" v-bind:key="index" label="x">{{ answer.title }}</el-radio>
        </el-radio-group>
      </div>
    </div>

    <!-- Vote Mode -->
    <div v-if="mode == 'voting'">
      <div>
        <el-radio-group v-model="input" @change="$emit('inputChange', $event)">
          <el-radio
            v-for="(answer, index) in data"
            v-bind:key="index"
            :label="index"
          >{{ answer.title }}</el-radio>
        </el-radio-group>
      </div>
    </div>

    <!-- Reporting Mode -->
    <div v-if="mode == 'reporting'">
      <div>
        <el-row type="flex" align="top">
          <el-col :span="12">
            <el-radio-group>
              <el-radio
                v-for="(answer, index) in data"
                v-bind:key="index"
                :label="index"
              >{{ answer.title }}</el-radio>
            </el-radio-group>
          </el-col>
          <el-col v-if="reportingResult.length != 0" :span="12">
            <div v-for="(answer, index) in data" v-bind:key="index" class="percentageResult">
              <strong>{{percentageResult(reportingResult[index].result)}}%</strong>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultipleChoice",
  props: {
    mode: String,
    data: Array,
    totalVotes: Number,
    reportingResult: Array
  },
  data() {
    return {
      input: Number
    };
  },
  methods: {
    percentageResult(amountVotes) {
      if (this.totalVotes === 0) {
        return 0;
      }

      return Math.round((amountVotes / this.totalVotes) * 100);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/variables";
h4 {
  margin: 0;
  margin-bottom: 20px;
}
.el-card {
  padding: 10px;
}
.el-radio {
  display: block;
  margin-top: 5px;
}
.percentageResult {
  display: block;
  color: $main-color;
}
</style>
