<template>
  <div>
    <el-steps class="m-t-5 m-b-5" :active="currentStep" finish-status="success" align-center>
      <el-step title="Umfrage erstellen"></el-step>
      <el-step title="Fragen erstellen"></el-step>
      <el-step title="Umfrage überprüfen"></el-step>
    </el-steps>

    <el-row type="flex" align="top" :gutter="30">
      <el-col :span="16">
        <Survey v-if="currentStep == 1" :data="survey" creationMode>
          <div class="noQuestionsInfo" v-if="survey.questions.length == 0">
            <h5>Neue Fragen unterhalb reinziehen</h5>
          </div>
          <draggable class="draggableContainer" :list="survey.questions" group="questions">
            <QuestionCreate
              v-for="(question, index) in survey.questions"
              :key="question.id"
              v-model="survey.questions[index]"
              @delete="deleteQuestion(index)"
            />
          </draggable>
        </Survey>
        <Survey v-else :data="survey" />
      </el-col>
      <el-col :span="8">
        <BasicSettings
          v-if="currentStep == 0"
          @update="updateSurveyData($event)"
          @next="nextStep()"
        />
        <QuestionSelection
          v-if="currentStep == 1"
          @update="updateSurveyData($event)"
          @next="nextStep()"
        />
        <SaveDraft v-if="currentStep == 2" @next="nextStep()" :isLoading="isLoading" />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Survey from "@/components/Surveys/Survey";
import QuestionCreate from "./QuestionCreate";
import BasicSettings from "./BasicSettings";
import QuestionSelection from "./QuestionSelection";
import SaveDraft from "./SaveDraft";
import { mapGetters } from "vuex";
import { mapState } from "vuex";

export default {
  components: {
    Survey,
    BasicSettings,
    QuestionSelection,
    QuestionCreate,
    SaveDraft,
    draggable
  },
  name: "surveyCreate",
  data() {
    return {
      currentStep: 0,
      isLoading: false,
      survey: {
        title: "Umfragetitel",
        description: "",
        isAnonymous: false,
        duration: "1d",
        status: "draft",
        teamId: "global",
        teamName: "Firmenweit",
        questions: []
      }
    };
  },
  methods: {
    nextStep() {
      if (this.currentStep < 2) {
        this.currentStep++;
      } else {
        // Save Survey
        this.saveSurvey();
      }
    },
    stepBack() {
      if (this.currentStep > 0 && this.currentStep < 3) {
        this.currentStep--;
      }
    },
    updateSurveyData(event) {
      // Populate survey with data
      this.survey = { ...this.survey, ...event };
    },
    deleteQuestion(index) {
      this.survey.questions.splice(index, 1);
    },
    async saveSurvey() {
      // Save survey template
      this.isLoading = true;
      let survey = this.survey;
      let response = await this.$store.dispatch("createSurveyTemplate", survey);

      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.noQuestionsInfo {
  padding: 25px;
  text-align: center;
  background: #eee;
  border: dotted 3px rgb(167, 167, 167);
}
.draggableContainer {
  min-height: 50px;
}
</style>
