<template>
  <div>
    <el-input type="textarea" :rows="2" placeholder="Antwort hier eingeben" disabled></el-input>
  </div>
</template>

<script>
export default {
  name: "OpenText"
};
</script>

<style lang="scss" scoped>
.el-button {
  padding-bottom: 0;
}
</style>
