var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 25, type: "flex", align: "middle" } },
        [
          _c("el-col", { attrs: { span: 18 } }, [
            _c("h2", [_vm._v("Survey Library")])
          ]),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.showTemplateCreation = true
                    }
                  }
                },
                [_vm._v("Neue Template")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Create new template",
            visible: _vm.showTemplateCreation,
            width: "80%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showTemplateCreation = $event
            }
          }
        },
        [_c("SurveyTemplateCreate")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }