var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SideBarCard",
    { attrs: { title: "Umfrage als Vorlage speichern" } },
    [
      _c("p", [_vm._v("Die Umfrage kann nun als Vorlage gespeichert werden.")]),
      _c(
        "div",
        { staticClass: "ta-r m-t-5" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.isLoading },
              on: {
                click: function($event) {
                  return _vm.onSubmit()
                }
              }
            },
            [_vm._v("Umfrage speichern")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }