<template>
  <SideBarCard title="Umfrage als Vorlage speichern">
    <p>Die Umfrage kann nun als Vorlage gespeichert werden.</p>
    <div class="ta-r m-t-5">
      <el-button @click="onSubmit()" type="primary" :loading="isLoading">Umfrage speichern</el-button>
    </div>
  </SideBarCard>
</template>

<script>
import SideBarCard from "@/components/Layout/SideBarCard";

export default {
  components: { SideBarCard },
  props: { isLoading: Boolean },
  methods: {
    onSubmit() {
      // Valid form
      this.$emit("next");
    }
  }
};
</script>
