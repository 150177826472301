import { render, staticRenderFns } from "./OpenText.vue?vue&type=template&id=397eb4c8&scoped=true&"
import script from "./OpenText.vue?vue&type=script&lang=js&"
export * from "./OpenText.vue?vue&type=script&lang=js&"
import style0 from "./OpenText.vue?vue&type=style&index=0&id=397eb4c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "397eb4c8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/wespond/wespond/src/admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('397eb4c8')) {
      api.createRecord('397eb4c8', component.options)
    } else {
      api.reload('397eb4c8', component.options)
    }
    module.hot.accept("./OpenText.vue?vue&type=template&id=397eb4c8&scoped=true&", function () {
      api.rerender('397eb4c8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/configuration/surveyTemplates/SurveyTemplateCreate/QuestionCreate/OpenText.vue"
export default component.exports