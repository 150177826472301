<template>
  <div>
    <el-row :gutter="25" type="flex" align="middle">
      <el-col :span="18">
        <h2>Survey Library</h2>
      </el-col>
      <el-col :span="6">
        <el-button @click="showTemplateCreation = true" type="primary">Neue Template</el-button>
      </el-col>
    </el-row>
    <el-dialog title="Create new template" :visible.sync="showTemplateCreation" width="80%">
      <SurveyTemplateCreate />
    </el-dialog>
  </div>
</template>

<script>
import SurveyTemplateCreate from "./SurveyTemplateCreate/";
export default {
  components: { SurveyTemplateCreate },
  data() {
    return {
      showTemplateCreation: false
    };
  }
};
</script>