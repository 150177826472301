var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.mode == "display"
      ? _c("div", [
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                _vm._l(_vm.data, function(answer, index) {
                  return _c("el-radio", { key: index, attrs: { label: "x" } }, [
                    _vm._v(_vm._s(answer.title))
                  ])
                }),
                1
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.mode == "voting"
      ? _c("div", [
          _c(
            "div",
            [
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function($event) {
                      return _vm.$emit("inputChange", $event)
                    }
                  },
                  model: {
                    value: _vm.input,
                    callback: function($$v) {
                      _vm.input = $$v
                    },
                    expression: "input"
                  }
                },
                _vm._l(_vm.data, function(answer, index) {
                  return _c(
                    "el-radio",
                    { key: index, attrs: { label: index } },
                    [_vm._v(_vm._s(answer.title))]
                  )
                }),
                1
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.mode == "reporting"
      ? _c("div", [
          _c(
            "div",
            [
              _c(
                "el-row",
                { attrs: { type: "flex", align: "top" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-radio-group",
                        _vm._l(_vm.data, function(answer, index) {
                          return _c(
                            "el-radio",
                            { key: index, attrs: { label: index } },
                            [_vm._v(_vm._s(answer.title))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm.reportingResult.length != 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        _vm._l(_vm.data, function(answer, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "percentageResult" },
                            [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.percentageResult(
                                      _vm.reportingResult[index].result
                                    )
                                  ) + "%"
                                )
                              ])
                            ]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }